<template>
  <div class=container>
      <table width="100%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"><font size="+2">Gallery of Separation Results</font></div></td>
          </tr>
          <tr>
            <td>Here, we show several examples of our separation results. In each case, we mention the separation method we have used. In all cases, the scene image is the sum of the computed direct and global images. However, to bring out the details, for some of the scenes we have brightened the direct and global images by a scale factor (between 1 and 2) with respect to the scene image. <strong>By clicking on each of the thumbnail images shown below, you can see the corresponding high resolution image.</strong><br>
            </td>
          </tr>

        <router-link :to="{path:'/projects/categories/project', query:{cid:'Computational Imaging',pid:'Fast Separation of Direct and Global Images'}}">(Back to Main Project Page) </router-link>
          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                <tbody><tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="47%"><strong>Eggs on a plate</strong></td>
                        <td width="53%"><div align="right"><strong>Method : Checkerboard<br>
                        </strong></div></td>
                      </tr>
                    </tbody></table>
                      <div align="right"></div></td>
                </tr>
                <tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Eggs_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Eggs_Scene.jpg" width="203" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Scene</strong></div></td>
                        <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Eggs_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Eggs_Direct.jpg" width="203" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Direct</strong></div></td>
                        <td width="33%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Eggs_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Eggs_Global.jpg" width="203" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Global</strong></div></td>
                      </tr>
                    </tbody></table>
                      <table width="100%" border="0" cellspacing="10" cellpadding="0">
                        <tbody><tr>
                          <td>In  this scene of eggs on a plate, the strong interreflections between the  eggs and between the eggs and the paper are captured in the global  image. The direct image shows the diffuse shading over the eggs and the  sharp shadows cast by the eggs onto each other. The orange paper can  be seen to glow in the global image due to diffusion of light through  the paper.</td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                    </tbody></table></td>
                </tr>
            </tbody></table></td>
          </tr>
          
          


          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                <tbody><tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="47%"><strong>Pink Carnation </strong></td>
                        <td width="53%"><div align="right"><strong>Method : Checkerboard<br>
                        </strong></div></td>
                      </tr>
                    </tbody></table>
                      <div align="right"></div></td>
                </tr>
                <tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Pink_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Pink_Scene.jpg" width="203" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Scene</strong></div></td>
                        <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Pink_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Pink_Direct.jpg" width="203" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Direct</strong></div></td>
                        <td width="33%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Pink_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Pink_Global.jpg" width="203" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Global</strong></div></td>
                      </tr>
                    </tbody></table>
                      <table width="100%" border="0" cellspacing="10" cellpadding="0">
                        <tbody><tr>
                          <td>In  the case of this flower, we see that in the direct image the shadows  cast by the petals on each other are strong and the petals&nbsp; themselves  appear grayish and somewhat listless. As a result, the direct image  looks more like that of a synthetic flower than a natural one. It is  interesting to note that most of the color of the flower arises from  global effects. These include the interreflection of light between the  petals as well as the diffusion of light through the petals.&nbsp; Both  these effects cause a "sharpening" of the spectral distribution of the  light. Due to this sharpening, the color of the light gets more reddish  after each bounce or diffusion.</td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                    </tbody></table></td>
                </tr>
            </tbody></table></td>
          </tr>
          



          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
              <tbody><tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td><strong>Peppers</strong></td>
                      <td><div align="right"><strong>Method : Checkerboard<br>
                      </strong></div></td>
                    </tr>
                  </tbody></table>
                    <div align="right"></div></td>
              </tr>
              <tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Peppers_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Peppers_Scene.jpg" width="203" height="152" border="0"></a><br>
                              <span class="style1">.</span><br>
                              <strong>Scene</strong></div></td>
                      <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Peppers_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Peppers_Direct.jpg" width="203" height="152" border="0"></a><br>
                              <span class="style1">.</span><br>
                              <strong>Direct</strong></div></td>
                      <td width="33%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Peppers_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Peppers_Global.jpg" width="203" height="152" border="0"></a><br>
                              <span class="style1">.</span><br>
                              <strong>Global</strong></div></td>
                    </tr>
                  </tbody></table>
                    <table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td>These  peppers exhibit very strong subsurface scattering. As a result, the  colors of the peppers are almost entirely captured in the global image.  The direct component mainly includes specular reflections, except in  the case of the green stalks that are rather more diffuse reflectors.</td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                  </tbody></table></td>
              </tr>
              <tr>
                <td><table width="100%" border="0" cellpadding="10" cellspacing="0" bordercolor="#000000" bgcolor="#F6F4EC">
                    <tbody><tr>
                      <td width="32%"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Peppers_Novel.jpg" width="203" height="152"></td>
                      <td width="68%" valign="top"><p><strong>Novel Images:</strong> This  video shows novel images generated by changing the hues of the peppers  in the global image and adding it back to the direct image.<br>
                              <a href="https://cave.cs.columbia.edu/old/projects/separation/videos/Peppers_640x480.mpg">MPEG</a> , <a href="https://cave.cs.columbia.edu/old/projects/separation/videos/Peppers/Old/Videos/Peppers_640x480.mov">Quicktime</a></p></td>
                    </tr>
                </tbody></table></td>
              </tr>
            </tbody></table></td>
          </tr>
          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                <tbody><tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td><strong>Grapes and Cheese</strong></td>
                        <td><div align="right"><strong>Method : Checkerboard<br>
                        </strong></div></td>
                      </tr>
                    </tbody></table>
                      <div align="right"></div></td>
                </tr>
                <tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Grapes_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_Grapes_Scene.jpg" width="203" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Scene</strong></div></td>
                        <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Grapes_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_Grapes_Direct.jpg" width="202" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
								<strong>Direct</strong></div></td>
                        <td width="33%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Grapes_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_Grapes_Global.jpg" width="202" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Global</strong></div></td>
                      </tr>
                    </tbody></table>
                      <table width="100%" border="0" cellspacing="10" cellpadding="0">
                        <tbody><tr>
                          <td>Similar  to peppers, the colors of the grapes are mostly due to global  illumination by subsurface scattering. The direct components for both sets  of grapes are similar and include both specular and diffuse reflections  from the surface. The cheeses in the scene also produce strong  subsurface effects. The direct image also captures the specular and  diffuse reflections from the plastic sheet wrapped around the cheeses.  The plastic wrapper is also ‘back’ illuminated by the cheeses, an  effect captured by the global image.</td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                    </tbody></table></td>
                </tr>
                <tr>
                  <td><table width="100%" border="0" cellpadding="10" cellspacing="0" bordercolor="#000000" bgcolor="#F6F4EC">
                      <tbody><tr>
                        <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Grapes_Novel.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_Grapes_Novel.jpg" width="92" height="69" border="0"></a><br>
                              <br>
                              <a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Grapes_Novel_Purple.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_Grapes_Novel_Purple.jpg" width="92" height="69" border="0"></a>  <a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Grapes_Novel_Green.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_Grapes_Novel_Green.jpg" width="92" height="69" border="0"></a><br>
                        </div></td>
                        <td width="65%" valign="top"><p><strong>Novel Images:</strong> These images were generated by changing the hues of the grapes in the global image and adding it back to the direct image.</p></td>
                      </tr>
                  </tbody></table></td>
                </tr>
            </tbody></table></td>
          </tr>
          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
              <tbody><tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td width="47%"><strong>Bread</strong></td>
                      <td width="53%"><div align="right"><strong>Method : Checkerboard<br>
                      </strong></div></td>
                    </tr>
                  </tbody></table>
                    <div align="right"></div></td>
              </tr>
              <tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Bread_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Bread_Scene.jpg" width="203" height="152" border="0"></a><br>
                              <span class="style1">.</span><br>
                              <strong>Scene</strong></div></td>
                      <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Bread_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Bread_Direct.jpg" width="203" height="152" border="0"></a><br>
                              <span class="style1">.</span><br>
                              <strong>Direct</strong></div></td>
                      <td width="33%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Bread_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Bread_Global.jpg" width="203" height="152" border="0"></a><br>
                              <span class="style1">.</span><br>
                              <strong>Global</strong></div></td>
                    </tr>
                  </tbody></table>
                    <table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td>Due  to the spongy nature of the bread, most of the light passes through the  surface and scatters beneath it to produce strong and complex global  effects. The direct image shows the 3D texture of the bread surface as  well as the albedo variations at the crust of bread and grains in the  bread.</td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                  </tbody></table></td>
              </tr>
            </tbody></table></td>
          </tr>
          


          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                <tbody><tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="47%"><strong>Tea Rose Leaf</strong></td>
                        <td width="53%"><div align="right"><strong>Method : Checkerboard<br>
                        </strong></div></td>
                      </tr>
                    </tbody></table>
                      <div align="right"></div></td>
                </tr>
                <tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Rose_Ventral_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Rose_Ventral_Scene.jpg" width="202" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Scene</strong></div></td>
                        <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Rose_Ventral_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Rose_Ventral_Direct.jpg" width="203" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Direct</strong></div></td>
                        <td width="33%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Rose_Ventral_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Rose_Ventral_Global.jpg" width="202" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Global</strong></div></td>
                      </tr>
                    </tbody></table>
                      <table width="100%" border="0" cellspacing="10" cellpadding="0">
                        <tbody><tr>
                          <td>Although  this leaf is thin, it exhibits a noticeable global component over its  entire area. This is because the entire leaf has a spongy mesophyll  layer, beneath the upper epidermis and palisade mesophyll layers, that  are slightly translucent. The global component is particularly strong  for the veins of the leaf. This is because the veins are even more  translucent as they are made of vascular tissues that carry water,  minerals and sap.</td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                    </tbody></table></td>
                </tr>
            </tbody></table></td>
          </tr>
          
          


          <tr>
            <td>&nbsp;</td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {
    name:'test'
}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
}
</style>